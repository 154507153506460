export const optionColors = [
  { value: "#BDC3C7", label: "Light Gray" },
  { value: "#C0392B", label: "Red" },
  { value: "#E74C3C", label: "Light Red" },
  { value: "#9B59B6", label: "Light Purple" },
  { value: "#8E44AD", label: "Purple" },
  { value: "#2980B9", label: "Dark Blue" },
  { value: "#3498DB", label: "Light Blue" },
  { value: "#1ABC9C", label: "Sea Green" },
  { value: "#16A085", label: "Dark Sea Green" },
  { value: "#27AE60", label: "Dark Green" },
  { value: "#2ECC71", label: "Light Green" },
  { value: "#F1C40F", label: "Yellow" },
  { value: "#E67E22", label: "Dark Orange" },
  { value: "#D35400", label: "Dull Orange" },
  { value: "#ECF0F1", label: "White" },
  { value: "#F39C12", label: "Orange" },
  { value: "#95A5A6", label: "Dull Gray" },
  { value: "#7F8C8D", label: "Dark Gray" },
  { value: "#34495E", label: "Light Black" },
  { value: "#FFA07A", label: "Salmon Red" },
  { value: "#F08080", label: "Light Coral" },
  { value: "#DFFF00", label: "Basanti" },
  { value: "#DE3163", label: "Pink" },
  { value: "#40E0D0", label: "Ferozi" },
];

export const pool1Options = [
  {
    label: "MATIC",
    value: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  },
  {
    label: "JPYC",
    value: "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
  },
  {
    label: "ETH",
    value: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  },
  {
    label: "USDT (ERC20)",
    value: "0x76A8387319ad56aB1D354c08c29fE6B08E708181",
  },
  {
    label: "PAX",
    value: "0x8E870D67F660D95d5be530380D0eC0bd388289E1",
  },
  {
    label: "USDC",
    value: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  },
  {
    label: "GBPT",
    value: "0x86B4dBE5D203e634a12364C0e428fa242A3FbA98",
  },
  {
    label: "EUROC",
    value: "0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c",
  },
  {
    label: "BNB",
    value: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  },
  {
    label: "CTY",
    value: "0x592Ed02b382C7d682851Ff241c016c4550E11E5a",
  },
  {
    label: "USDT (BEP20)",
    value: "0x19037ab0c4529CD90F4f7e408AbCDcF72338481b",
  },
  {
    label: "BUSD",
    value: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  },
  {
    label: "OPY",
    value: "0x7a656f418afc09eaF4AE8b75EAe74fE09E7A7706",
  },
  {
    label: "FTY",
    value: "0x500f8c7a9eE51C5b6f6b8D836d84340d7272D84E",
  },
  {
    label: "MET$",
    value: "0xDd770074aa548B66Ab54CF0f6E67F9354E3b732e",
  },
  {
    label: "SOLANA",
    value: "SOLANA",
  },
];
export const pool2Options = [
  {
    label: "MATIC",
    value: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  },
  {
    label: "JPYC",
    value: "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
  },
  {
    label: "ETH",
    value: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  },
  {
    label: "USDT (ERC20)",
    value: "0x76A8387319ad56aB1D354c08c29fE6B08E708181",
  },
  {
    label: "PAX",
    value: "0x8E870D67F660D95d5be530380D0eC0bd388289E1",
  },
  {
    label: "USDC",
    value: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  },
  {
    label: "GBPT",
    value: "0x86B4dBE5D203e634a12364C0e428fa242A3FbA98",
  },
  {
    label: "EUROC",
    value: "0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c",
  },
  {
    label: "BNB",
    value: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  },
  {
    label: "CTY",
    value: "0x592Ed02b382C7d682851Ff241c016c4550E11E5a",
  },
  {
    label: "USDT (BEP20)",
    value: "0x19037ab0c4529CD90F4f7e408AbCDcF72338481b",
  },
  {
    label: "BUSD",
    value: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  },
  {
    label: "OPY",
    value: "0x7a656f418afc09eaF4AE8b75EAe74fE09E7A7706",
  },
  {
    label: "FTY",
    value: "0x500f8c7a9eE51C5b6f6b8D836d84340d7272D84E",
  },
  {
    label: "MET$",
    value: "0xDd770074aa548B66Ab54CF0f6E67F9354E3b732e",
  },
  {
    label: "SOLANA",
    value: "SOLANA",
  },
  {
    label: "SOLPY",
    value: "SOLPY",
  },
];
export const TaxSymbol = [
  {
    label: "SOLANA",
    value: "SOLANA",
  },
];
export const getStatusBadge = (status) => {
  switch (status) {
    case "pending":
      return (
        <div className="mt-1 text-center bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2 py-2 rounded">
          Pending
        </div>
      );
    case "pending_withdraw":
      return (
        <div className="mt-1 text-center bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2 py-2 rounded">
          pending_withdraw
        </div>
      );
    case "in-progress":
      return (
        <div className="mt-1 text-center text-nowrap bg-primary text-white text-sm font-medium mr-2 px-2 py-2 rounded">
          In-Progress
        </div>
      );
    case "waiting_confirmation":
      return (
        <div className="mt-1 text-center text-nowrap bg-primary text-white text-sm font-medium mr-2 px-2 py-2 rounded">
          waiting_confirmation
        </div>
      );
    case "waiting":
      return (
        <div className="mt-1 text-center text-nowrap bg-primary text-white text-sm font-medium mr-2 px-2 py-2 rounded">
          waiting
        </div>
      );
    case "Waiting":
      return (
        <div className="mt-1 text-center text-nowrap bg-primary text-white text-sm font-medium mr-2 px-2 py-2 rounded">
          Waiting
        </div>
      );
    case "approved":
      return (
        <div className="mt-1 text-center bg-green-100 text-green-800 text-sm font-medium mr-2 px-1 py-2 rounded">
          Approved
        </div>
      );
    case "active":
      return (
        <div className="mt-1 bg-green-100 text-green-800 w-[100%] md:w-[40%] text-center text-sm font-medium  px-1 py-2 rounded">
          Active
        </div>
      );
    case "Completed":
      return (
        <div className="mt-1 bg-green-100 text-green-800 w-[100%] md:w-[100%] text-center text-sm font-medium  px-1 py-2 rounded">
          Completed
        </div>
      );
    case "rejected":
      return (
        <div className="mt-1 text-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
          Rejected
        </div>
      );
    case "Rejected":
      return (
        <div className="mt-1 text-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
          Rejected
        </div>
      );
    case "Declined":
      return (
        <div className="mt-1 text-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
          Declined
        </div>
      );
    case "Failed":
      return (
        <div className="mt-1 text-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
          Failed
        </div>
      );
    case "error":
      return (
        <div className="mt-1 text-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
          Error
        </div>
      );
    case "failed":
      return (
        <div className="mt-1 text-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
          Failed
        </div>
      );
    case "deactivated":
      return (
        <div className="mt-1 text-center bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-1 py-2 rounded">
          Deactivated
        </div>
      );
    default:
      return <div className="badge">{status || 'Unknown'}</div>;
  }
};
export const chainIdToName = {
  137: "Polygon ",
  1: "ETH ",
  97: "BSC",
  56: "BSC",
  80001: "Mumbai ",
  11155111: "Sepolia ",
  123: "SOLANA",
  321: "Bitcoin",
  111: "TRON",
};
export const optionOrderStatus = [
  { value: "Waiting", label: "Waiting" },
  { value: "Completed", label: "Completed" },
  { value: "Failed", label: "Failed" },
  { value: "pending", label: "pending" },
  { value: "waiting_confirmation", label: "Waiting Confirmation" },
  { value: "pending_withdraw", label: "Pending Withdraw" },
];
export const historyOption = [
  { label: "Bank History", value: "bank" },
  { label: "Virtual History", value: "virtual" },
  { label: "onChain History", value: "onchain" },
];
export const tableConfig = {
  isEditable: false,
  isSortable: false,
  isResizable: false,
  withPagination: true,
  withSearchEngine: false,
  manualPageSize: [10, 20, 30, 40],
  placeholder: "Search...",
  isPagination: true,
};
