import styled from 'styled-components';
import {
    Bullet,
    DashboardChartLegendTable,
    DashboardPieChartContainer,
    DashboardPieChartFlex,
} from '../../../../src/containers/Dashboards/BasicDashboardComponents';
import {
    colorAdditional, colorDarkText, scrollbarStyles, colorAccent,
} from '@/utils/palette';
import BalanceReportInfo from './BalanceReportInfo';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import useMatchMedia from '../../../shared/hooks/useMatchMedia';
import Panel from '../../../shared/components/Panel';
import { Ring } from '@uiball/loaders';
import { Legend, Pie, Tooltip } from 'recharts';
import { useSelector } from 'react-redux';
import getTooltipStyles from '@/shared/helpers';
import { Switch, Stack } from '@mui/material';
import numeral from 'numeral';
import { useParams } from 'react-router';
import { getUserInternalWalletBalance } from '../../../utils/services/user.service';
import { optionColors } from '../../../redux/constants/constans';

const BalanceReport = () => {
    const tooltipFormatter = (value, name, entry) => {
        return [
            <span key="custom-label">{`${entry?.payload?.symbol
                } : ${numeral(entry.payload.graphVal).format('0,0.0000')}`}</span>
        ];
    };
    const CustomSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: colorAccent,
            '&:hover': {
                backgroundColor: '#4ce1b65e',
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: colorAccent,
        },
    }));
    const onMouseMove = (e) => {
        if (e.tooltipPosition) {
            setCoordinates({ x: rtl.direction === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
        }
    };
    const [filterData, setFilterData] = useState([])
    const [data, setData] = useState([])
    const [allBalances, setAllBalances] = useState([])
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const [isLoading, setIsLoading] = useState(false);
    const isMdBreakpoint = useMatchMedia(1199);
    const themeName = useSelector(state => state.theme.className);
    const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
    const [twoFactors2, setTwoFactor2] = useState(false);
    const rtl = useSelector(state => state.rtl);
    const { id } = useParams();
    useEffect(() => {
        const res = filterData?.map((item, index) => {
          const newVal = Number(item?.quantity);
          const priceValue = newVal.toFixed(2) || 0;
          const graphVal = parseFloat(priceValue );
          const fill = optionColors[index]?.value || '#ffff';
          const symbol = item.symbol;
          return { fill, graphVal, symbol };
        });
        setData(res);
      }, [filterData]);
    const handleTwoFactorChange = async (e) => {
        const value = e.target.checked;
        if (value) {
          // Filter out coins with quantity greater than 0 in coinList or pendingBalance
          const filteredCoinList = filterData.filter((coin) => {
            // const pendingCoin = pendingBalance.find((balance) => balance.symbol === coin.symbol);
            // return Number(coin.quantity) > 0 || (pendingCoin && Number(pendingCoin.quantity) > 0);
          });

          const resGraphValues = data.filter((data) => data.graphVal > 0);

          setFilterData(filteredCoinList);
          setData(resGraphValues);
          setTwoFactor2(true);
        } else {
          // Set the entire data
          setFilterData(allBalances);
          setData(allBalances);
          setTwoFactor2(false);
        }
    };

    useEffect(() => {
        const fetchBalance = async () => {
            setIsLoading(true)
            const payload = {
                userId: id
            }
            const res = await getUserInternalWalletBalance(payload)
            setFilterData(res?.data?.data)
            setAllBalances(res?.data?.data)
            setIsLoading(false)
        }
        fetchBalance()
    }, [id])
    return (

        <div className='flex flex-col flex-lg-row w-full'>

            <BalanceReportInfo />

            <Panel lg={12} xxl={9} title={'Balance Report'} isLoading={isLoading} >

                <div
                    className="loader-overlay"
                    style={{
                        display: isLoading ? "flex" : "none", // Use flex display when loading
                        justifyContent: "center", // Center horizontally
                        alignItems: "center", // Center vertically
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.6)",
                        zIndex: 999000,
                    }}
                >
                    <Ring size={65} color="#00897B" />
                </div>
                {isLoading ? <Spinner style={{ color: colorAccent }} />
                    : (
                        <>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4}>
                                    <Stack direction="row" spacing={1}>
                                        <CustomSwitch
                                            {...label}
                                            value={twoFactors2}
                                            checked={twoFactors2}
                                            onChange={(e) => {
                                                handleTwoFactorChange(e);
                                            }}
                                            color="default"
                                        />
                                        <div className="mx-auto text-white mt-1">Hide Zero Balance</div>
                                    </Stack>
                                </Col>
                            </Row>
                            <div className='d-flex d-xl-none w-100 align-items-center justify-content-center  ' >
                                <DashboardCoinsChartContainer width="90%">
                                    <DashboardPieChartFlex>
                                        <Tooltip position={coordinates} {...getTooltipStyles(themeName)} formatter={tooltipFormatter} />
                                        <Pie
                                            data={data}
                                            dataKey="graphVal"
                                            cy={210}
                                            innerRadius={40}
                                            outerRadius={60}
                                            label
                                            onMouseMove={onMouseMove}
                                            minAngle={20}
                                            width={'50%'}
                                        />


                                    </DashboardPieChartFlex>
                                </DashboardCoinsChartContainer>
                            </div>
                            <div dir={rtl.direction} >
                                <DashboardCoinsChartContainer width="100%">
                                    <DashboardPieChartFlex>
                                        <Tooltip position={coordinates} {...getTooltipStyles(themeName)} formatter={tooltipFormatter} />
                                        <Pie
                                            data={data}
                                            dataKey="graphVal"
                                            cy={230}
                                            innerRadius={80}
                                            outerRadius={100}
                                            className='d-none d-xl-block' // Apply responsive classes here
                                            label
                                            onMouseMove={onMouseMove}
                                            minAngle={20}
                                            width={'50%'}
                                        />

                                        <Legend
                                            layout="vertical"
                                            verticalAlign={isMdBreakpoint ? 'bottom' : 'top'}
                                            content={
                                                <DashboardChartLegendTable>
                                                    <LegendCol>
                                                        <LegendColTitle>Coin List</LegendColTitle>
                                                        <LegendColContent>
                                                            {filterData.map((entry, index) => (
                              <li
                                key={index}
                                style={{
                                  width: "max-content",
                                  display: 'flex',
                                  flexWrap: 'nowrap',
                                  alignItems: 'center'
                                }}
                              >

                                <Bullet color={optionColors[index]?.value} />

                                {entry?.symbol}
                              </li>
                              //  )
                            ))}
                                                            

                                                        </LegendColContent>
                                                    </LegendCol>
                                                    <LegendCol>
                                                        <LegendColTitle>Quantity</LegendColTitle>
                                                        <LegendColContent>
                                                            {filterData?.map((entry,idx) => (
                              <li key={idx} style={{
                                width: "max-content",
                                display: 'flex',
                                flexWrap: 'nowrap',
                                alignItems: 'center'
                              }}> 

                                {numeral(entry.quantity).format('0,0.00000')}
                              </li>
                            ))}
                                                          

                                                        </LegendColContent>
                                                    </LegendCol>

                                                    <LegendCol>
                                                        <LegendColTitle>Balance</LegendColTitle>
                                                        <LegendColContent>
                                                            {filterData.map((entry,idx) => (
                              <li 
                              key={idx}
                              style={{
                                width: "max-content",
                                display: 'flex',
                                flexWrap: 'nowrap',
                                alignItems: 'center'
                              }}>
                                {numeral(entry.balance).format('0,0.0000')}
                              </li>
                            ))}
                                                            

                                                        </LegendColContent>
                                                    </LegendCol>



                                                </DashboardChartLegendTable>
                                            }
                                            align={isMdBreakpoint ? 'center' : 'right'}
                                        />
                                    </DashboardPieChartFlex>
                                </DashboardCoinsChartContainer>
                            </div>
                        </>
                    )}

            </Panel>
        </div>

    )
}
export default BalanceReport;
const DashboardCoinsChartContainer = styled(DashboardPieChartContainer)`
  min-height: 360px !important;
  height: 100% !important;
  
  svg {
    min-height: 360px;
    position: absolute;
    left: 0;
    top: 0;
    
    @media(max-width: 1640px) {
      max-width: 90%;
    }
    
    @media(max-width: 1199px) {
      max-width: 100%;
    }
    
    @media(max-width: 768px) {
      height: 80% !important;
    }
    
    @media(max-width: 400px) {
      height: 60% !important;
    }

    .recharts-pie {
      transform: translateX(-60px);
      
      @media(max-width: 1919px) {
        transform: translateX(0);
      }
    }
  }
  
  .recharts-legend-wrapper {
    position: relative !important;
    max-width: 55%;
    width: 100% !important;
    z-index: 5;

    @media(max-width: 1919px) {
      max-width: 50%;
    }
    @media(max-width: 1199px) {
      max-width: 100%;
    }
    @media(max-width: 768px) {
      overflow-x: auto;
      
      ${scrollbarStyles};
    }
  }
`;

const LegendCol = styled('div')`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled('div')`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled('ul')`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;